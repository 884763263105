"use client";

import { inter } from "@holdenmatt/shadcn-kit";

import ErrorPage from "@/components/ErrorPage";

/**
 * Global error handler for root layouts.
 *
 * @see https://nextjs.org/docs/app/building-your-application/routing/error-handling#handling-errors-in-root-layouts
 */
export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  return (
    <html lang="en">
      <body className={inter.className}>
        <ErrorPage error={error} reset={reset} />
      </body>
    </html>
  );
}
